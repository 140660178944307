import {Howl} from 'howler';

export default class AudioService {


  bgSound;
  soundToPlay;

  name = 'AudioService';

   settings = {
    sndFolder: '/snd/',
    sound_antwortRichtig:           'richtig.wav',
    sound_antwortFalsch:            'falsch.wav',
    sound_login:                    'login.wav',
  };

  // ===================== AUDIO ========================================

  setBGSound(_name: string) {
    const _filename = this.settings.sndFolder + _name;
    this.bgSound = new Howl({
      src: [_filename],
      autoplay: true,
      loop: true,
      volume: 0.5,
      onend: function() {
        console.log('sound Finished! now loop it again ');
      }
    });
  }

  playSample(_name: string) {
   this.playSampleWithCallback(_name, function() {
        console.log(' this is the callback - playSampleWithCallback - sound Finished! do something ? ');
      });
  }

  playSampleWithCallback(_name: string, _callback) {
     const _filename = this.settings.sndFolder + _name;
     console.log(' playSampleWithCallback() : ', _filename);
      this.soundToPlay = new Howl({
        src: [_filename],
        autoplay: true,
        loop: false,
        volume: 0.8,
        onend: _callback
      });
  }

  stopSounds() {
     console.log('stopSounds ');
    if (this.bgSound) {
      this.bgSound.stop();
    }
    if (this.soundToPlay) {
      this.soundToPlay.stop();
    }
  }

// ============== END AUDIO ======================

}