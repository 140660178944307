<template>
	<div>
    <div class="questionContainer" >
      <h2>{{currentQuestion.question}}</h2>
    </div>

      <div  class="antwortContainer">
        <h3  
        @click = "showChoosenAnswer(1)"
          class="antwort" 
          :style="classStyleButton(0)">
          <img class="buzzer" src="/img/quiz/buzzer_gruen.png">
          <span class="button1"> {{currentQuestion.a1}} </span>
        </h3>
        <h3 
          @click = "showChoosenAnswer(2)"
          class="antwort" 
          :style="classStyleButton(1)">
          <img  class="buzzer" src="/img/quiz/buzzer_gelb.png">
          <span  class="button3"> {{currentQuestion.a2}} </span>
        </h3>
        <h3 
          @click = "showChoosenAnswer(3)"
          class="antwort" 
          :style="classStyleButton(2)"><img class="buzzer" src="/img/quiz/buzzer_rot.png">
         <span  class="button3"> {{currentQuestion.a3}} </span>
        </h3>
        <h3 
          @click = "showChoosenAnswer(4)"
          class="antwort" 
          :style="classStyleButton(3)">
          <img  class="buzzer" src="/img/quiz/buzzer_blau.png">
          <span  class="button4"> {{currentQuestion.a4}} </span>
        </h3>
      </div>

	</div>
</template>

<script>
  import { mapGetters } from "vuex"; //Variablen
  import { mapActions } from "vuex"; //Funktionen
  import  AudioService  from "@/services/AudioService";

  export default {
    
	name: 'FrageDialog',
	props: ['config', 'quizData'],
	emits: ['emitAnswer'],
    
    data: () => ({
      currentQuestion: {},

      STEP_STARTSCREEN              : 1,
      STEP_WARTEN_AUF_ANTWORT       : 2,
      STEP_ANTWORT_GEGEBEN_TIMEOUT  : 3,
      STEP_ANZEIGE_LOESUNG          : 4,
      STEP_AUSWERTUNG               : 5,

      QUESTION_TYPE_4               : 4,
      QUESTION_TYPE_3               : 3,
      QUESTION_TYPE_2               : 2,

      currentStep : 1,
      audioService: null,

      bgColor: [], // = [this.colorInit, this.colorInit, this.colorInit, this.colorInit];
      bgImg: ['/img/quiz/button_bg_schwarz.png', '/img/quiz/button_bg_schwarz.png',
        '/img/quiz/button_bg_schwarz.png', '/img/quiz/button_bg_schwarz.png'],
      borderColor: [],
      buttonColor: [], // = [gelb, blau , rot, grün];


      colorRichtig : '#98c723',
      colorInit    : '#666666',
      colorFalsch  : '#ff0000',
      colorChoosen  : '#999999',

      colorGelb : '#f9c401',
      colorBlau : '#0073bb',
      colorRot : '#ca2411',
      colorGruen : '#309e35',
      colorGrau  : '#666666',

      sound_antwortRichtig:           'richtig.wav',
      sound_antwortFalsch:            'falsch.wav',
      sound_login:                    'login.wav',

      settings :  {
        sound_antwortRichtig:           'richtig.wav',
        sound_antwortFalsch:            'falsch.wav',
        sound_login:                    'login.wav',
        maxFragenCount:                 6,
        totalTimeToShowAnswer:          4000,
        offsetTimeToShowCorrectAnswer:  2000,
        nextStepTimeout:                1000,
        timeShowEndscreen :             7000,
      },
    }),

    created () {
      this.initColors()
      this.currentQuestion = this.getRandomQuestion()
    },

    mounted () {
      this.audioService = new AudioService();
      this.audioService.playSample('login.wav')
      this.currentStep = this.STEP_WARTEN_AUF_ANTWORT
      window.addEventListener("keydown", e => {   //e => == function(e)
        console.log(this.$options.name + " -> keydown: " + String.fromCharCode(e.keyCode));
        this.handleKeyboardEvent(e);
      });
    },

    computed: {
	// 	...mapGetters(
    //   ['showOverlay']
    // ),
      styleBorderColor() {
        return  this.borderColor
      },
      styleBgColor() {
        return  this.bgColor
      }
    },

    methods: {
      //   ...mapActions([
      //   ]),

      emitAnswer: function() {
        console.log(this.$options.name + ' ->  emitAnswer ')
        this.$emit('emitAnswer')
      },

      initColors: function () {
          this.bgColor = [this.colorInit, this.colorInit, this.colorInit, this.colorInit];
          this.borderColor = [this.colorGrau, this.colorGrau, this.colorGrau, this.colorGrau],
          this.buttonColor = [ this.colorGruen, this.colorGelb, this.colorRot, this.colorBlau] // = [gelb, blau , rot, grün];
      },

        // @HostListener('document:keydown', ['$event'])
      handleKeyboardEvent: function (event) {
        console.log('key on quiz' , event.key);

        if (this.timeout === true) {
          console.log('timeout bzw. DeadTime from last Buzzer call : tue - so return');
          return;
        }

        const key = event.key;

        switch (this.currentStep) {
          case this.STEP_STARTSCREEN: {
            this.nextStep();
            break;
          }

          case this.STEP_WARTEN_AUF_ANTWORT: {
            if (key === '1' || key === '2'
              || (this.currentQuestion.type > this.QUESTION_TYPE_2 && key === '3' )
              || (this.currentQuestion.type > this.QUESTION_TYPE_3 && key === '4') )  {
              this.showChoosenAnswer(Number(key));
            }
            if (key === 'A' || key === 'a') {
              this.showChoosenAnswer(1);
            }
            if (key === 'B' || key === 'b') {
              this.showChoosenAnswer(2);
            }
            if ( (this.currentQuestion.type > this.QUESTION_TYPE_2 ) && ( key === 'C' || key === 'c') ) {
              this.showChoosenAnswer(3);
            }
            if ( (this.currentQuestion.type > this.QUESTION_TYPE_3) && ( key === 'D' || key === 'd')) {
              this.showChoosenAnswer(4);
            }
            break;
          }
        }
      },

      showChoosenAnswer: function (_nr) {
        console.log(' -> showChoosenAnswer() : ' + _nr);
        if (this.currentStep === this.STEP_WARTEN_AUF_ANTWORT) {
          const id = _nr - 1;
          this.currentAnswerNr = id;
          this.feedbackAntwortChoosen(id);
          this.nextStep();
        }
      },

      checkAnswer: function () {
        console.log(this.$options.name + ' -> checkAnswer() : ');
        const _nr = this.currentAnswerNr + 1;
        const id = this.currentAnswerNr;
        const raID = this.currentQuestion.richtigeAntwort - 1;
        if (_nr === this.currentQuestion.richtigeAntwort ) {
          this.richtig = this.richtig + 1;
  //        this.http.get('/sendchar/' + this.ls.getCharFromId(id), {responseType: 'text'});
        } else if ( this.currentQuestion.richtigeAntwort === 0 ) {
          this.richtig = this.richtig + 1;
    //      this.http.get('/sendchar/' + this.ls.getCharFromId(1), {responseType: 'text'});
    //      this.http.get('/sendchar/' + this.ls.getCharFromId(2), {responseType: 'text'});
      //    this.http.get('/sendchar/' + this.ls.getCharFromId(3), {responseType: 'text'});
        //  this.http.get('/sendchar/' + this.ls.getCharFromId(4), {responseType: 'text'});
        } else {
          this.falsch = this.falsch + 1;
        }
        this.feedbackAntwortRFSynchron(id, raID)
        const source = setTimeout(() => {
                           this.nextStep()
                        }, this.settings.totalTimeToShowAnswer);
      },

    getQuestionById: function (_id) {
        console.log(this.$options.name + ' -> getQuestionById() : ' + _id);
          // get question linear by id
        if (_id >= 0 && _id < this.quizData.FA.length) {
          const q = this.quizData.FA[_id];
          console.log('linear Question  ', q);
          return q;
        } else {
          console.log(' ERRROR - QUESTION NOT EXIST :::: ', _id);
        }
      },

      /** 
       * return  random question :
       */
      getRandomQuestion: function () {
        console.log(this.$options.name + ' -> getRandomQuestion() : ');
          let _id = Math.floor(Math.random() * this.quizData.FA.length);
          const q = this.getQuestionById(_id)
          return q;
      },

      nextStep: function () {
        console.log(this.$options.name + ' -> nextStep() -> this.currentStep : ', this.currentStep);
        this.finishStep(this.currentStep);
        if (this.currentStep === this.STEP_ANZEIGE_LOESUNG ) {
          // exit 
          this.emitAnswer()
        } else {
          const temp = this.currentStep + 1;
          this.setStep(temp);
        }
      },

      finishStep: function (_step) {
          switch (_step) {
            case this.STEP_STARTSCREEN: {
              this.resetQuiz();
              break;
            }
          }
      },

      setStep: function (_step) {
        this.timeout = true;
        const source = setTimeout(() => {
            this.timeout = false
        }, this.settings.nextStepTimeout);
        console.log('set step: ' , _step);
        this.currentStep = _step;
        switch (this.currentStep) {
          case this.STEP_STARTSCREEN: {
            break;
          }
          case this.STEP_WARTEN_AUF_ANTWORT: {
            this.nextQuestion();
            break;
          }
          case this.STEP_ANTWORT_GEGEBEN_TIMEOUT: {
            const source2 = setTimeout(() => {
                            this.nextStep()
                        }, this.settings.offsetTimeToShowCorrectAnswer);
            break;
          }
          case this.STEP_ANZEIGE_LOESUNG: {
            this.checkAnswer();
            break;
          }
          case this.STEP_AUSWERTUNG: {
            const source2 = setTimeout(() => {
                            this.nextStep
                        }, this.settings.timeShowEndscreen);
            break;
          }
        }
      },

      resetQuiz() {
        this.currentQuestion    = this.getRandomQuestion();
        console.log('resetQuiz', this.currentQuestion );
      },


      feedbackAntwortChoosen: function (_id) {
        console.log(this.$options.name + ' -> feedbackAntwortChoosen() -> ' + _id );
        this.initColors()
        this.bgColor[_id] = this.buttonColor[_id]
        this.borderColor[_id] = this.buttonColor[_id];
        this.audioService.playSample(this.sound_login);
      },

      feedbackAntwortRichtig(_id) {
        console.log(this.$options.name + ' -> feedbackAntwortRichtig() -> ' + _id );
          this.initColors()
          this.bgColor[_id] = this.colorRichtig;
          this.bgImg[_id] = '/img/quiz/button_bg_gruen.png';
      },
      feedbackAntwortFalsch(_id) {
        console.log(this.$options.name + ' -> feedbackAntwortFalsch() -> ' + _id );
          this.initColors()
          this.bgColor[_id] = this.colorFalsch;
          this.bgImg[_id] = '/img/quiz/button_bg_rot.png';
      },

      feedbackAntwortRFSynchron(_id, _richtig) {
        console.log(this.$options.name + ' -> feedbackAntwortRFSynchron() -> ' + _id + ' richtig: ' + _richtig);
        if (_id === _richtig) {
          this.audioService.playSample(this.settings.sound_antwortRichtig);
          this.feedbackAntwortRichtig(_id);
        } else if (_richtig === -1 ) {
          // alle antworten sind richtig
          this.audioService.playSample(this.settings.sound_antwortRichtig);
          this.feedbackAntwortRichtig(0);
          this.feedbackAntwortRichtig(1);
          this.feedbackAntwortRichtig(2);
          this.feedbackAntwortRichtig(3);
        } else {
          // falsche antwort
          this.audioService.playSample(this.settings.sound_antwortFalsch);
          this.feedbackAntwortFalsch(_id);
          this.feedbackAntwortRichtig(_richtig);
        }
      },

      classStyleButton(i) {
        return { 'border-color' : this.borderColor[i], 'background-color' :  this.bgColor[i], 'background-image' : 'url("' + this.bgImg[i] + '")'};
     },

    },
  }
</script>

<style lang="scss" scoped>

.buttonNormal {
  border-color: grey;
  background-image: url("/assets/img/button_bg_schwarz.png");
}

h2 {
  color: white;
  margin-left: 5vw;
  font-size: 2vw;
  font-family: Milo, Arial, sans-serif;
}

  .antwortContainer {
        top: 55vh;
        position: absolute;
        width: 80vw;
        display: flex;
        flex-basis: 0;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 10vw;
        
        h3 {
            background-image: url("/assets/img/button_bg_schwarz.png");
            background-size: cover;
            font-size: 1.6vw;
            font-weight: 400;
            width: 30vw;
            color: white;
            border-style: solid;
            border-width: 0.4vw;
            border-radius: 3vw;
            max-width: 30vw;
            flex-grow: 1;
            padding: 2vw 2vw;
            border-color: azure;
            position: relative;
            display: flex;
            align-items: center; /* align vertical */
            
            &:hover {
                background-color: #c7f0ea;
            }
            span {
                margin-right: 3rem;
                position: absolute;
                width: 70%;
                left: 20%;
            }
            .buzzer {
                margin: -2.5vh -1vh;
                width: 4vw;
                position: relative;
            }
        }
    }

    .questionContainer {
        position: absolute;
        top: 25vh;
        width: 80%;
        line-height: 3vw;
        h2 {
            font-size: 3vw;
            font-weight: 400;
            margin-block-start: 3vh;
            line-height: 5vw;
            line-height: 3vw;
        }
    }

  @font-face {
    font-family: Milo;
    src: url('/fonts/MiloSansOffc/MiloSansOffc-Medium.ttf')
    format("truetype");
  }



</style>
