<template>
  <div> 

    <!-- ============  Frage Dialog ================ -->
    <div v-if="showFrageDialog" class="frageContainer">
      <frage-dialog
        :config="config"
        :quizData="JSON.parse(wheeldata.felder[gift].frageJsonText)"
        @emitAnswer = "waitAfterAnswer()"/>
    </div>

    <!-- ============  Glücksrad Container  ================ -->
    <div class="wheel_wrap" @click.prevent="spinTheWheel">

      <!-- background --> 
      <div v-if="wheeldata.bg" class="background" >
        <img class="backgroundimg" :src="wheeldata.bg" />
      </div>
      <div v-else class="background" :style="setBGColor()">
      </div>

      <!-- ,,,, icons Logos und so ... --> 
      <div class="nSBilder" :style="positionNSBilder(index)"
        v-for="(item, index) in  config.nichtSkalierbareBilder" :key="index">
          <img class="nsImg" :src = item.src>
      </div>

      <!-- Preis Animations --> 
      <div class="awardContainer"
        :class="animationState">
        <img class="galleryImg" :src="awardImg" @animationend="showFrage()" >
      </div>

      <!-- GlücksRad --> 
      <div class="radContainer" v-if="showwheel">
        <Wheel
            class="drehscheibe"
            :class="hidden"
            :style="styleFromWheelData()"
            ref="wheel"
            :gift="gift"
            @done="waitBeforeDone"
            :config="wheeldata"
        />
      </div>
    </div>
    <!-- ============  ENDE Glücksrad Container  ================ -->

  </div>
</template>

<script>
import Wheel from "@/components/Wheel.vue"
import FrageDialog from "@/components/FrageDialog.vue"

export default {
  name: 'GluecksRad',

  components: {
    Wheel,
    FrageDialog,
  },

 props: ['config'],
 emits: ['emitDone'],

  data() {
    return {
      showwheel: false,
      clicked: false,
      gift: 0,
      wheeldata: [],
      rotDeg: 0,
      t: 0,
      r: 0,
      useAnimation: false,
      awardImg: null,
      animationState: '',
      useFrageDialog: false,
      showFrageDialog: false,
      hidden: '',
    }
  },

  created() {
      this.setdata(this.config)
  },

  mounted() {
    console.log(this.$options.name + " -> mounted");
    window.addEventListener("keydown", e => {   //e => == function(e)
      console.log(this.$options.name + " -> keydown: " + String.fromCharCode(e.keyCode));
      this.spinTheWheel();
    });
  },

  computed: {
  },

  methods: {
    waitAfterAnswer: function () {
      console.log(this.$options.name + ' ->  emitDone ')
      setTimeout(() => {this.zeigeWheel()}, this.config.waitBeforeDone * 1000)
    },

    setdata: function (_data) {
      console.log(this.$options.name + " -> setData : ", _data);
      this.wheeldata = _data;

      // es gibt die Mäglichkeit, die Warscheinlichkeiten für jedes Feld zu setzten 
      if (this.wheeldata.cheatFrequency == true) {
        this.gift = this.getCheatetGift()

      } else {
        // wir bleiben beim PseudoZufall 
        this.gift = this.randomGift();
      }

      if (this.wheeldata.useSound === true) {
        this.startSound = new Audio(this.wheeldata.startsound);
        this.stopSound = new Audio(this.wheeldata.stopsound);
      }
      this.showwheel = true;
    },

    styleFromWheelData: function () {
      let fromWidthOrHeight = "vh"
      if (this.config.ausrichtung == "portrait") {
         fromWidthOrHeight = "vw";
       }
      return { 'margin-left' : this.wheeldata.posX + "vw",
        'margin-top' : this.wheeldata.posY + "vh",
        'width' : this.wheeldata.size + fromWidthOrHeight};
    },

    positionNSBilder: function (_index) {
      let fromWidthOrHeight = "vh"
      if (this.config.ausrichtung == "portrait") {
         fromWidthOrHeight = "vw";
      }
      return { 'left' : this.wheeldata.nichtSkalierbareBilder[_index].posX + "vw",
        'top' : this.wheeldata.nichtSkalierbareBilder[_index].posY + "vh",
        'max-width' : this.wheeldata.nichtSkalierbareBilder[_index].size + fromWidthOrHeight,
        'min-width' : this.wheeldata.nichtSkalierbareBilder[_index].size + fromWidthOrHeight};
    },

    setBGColor: function () {
      return { 'background-color' : this.wheeldata.bgColor};
    },

    resetValues: function () {
      this.rotDeg = Math.random * 360 + 500
    },

    waitBeforeDone: function () {
      setTimeout(() => {this.done()}, this.config.waitBeforeDone * 1000)
    },

    done: function (params) {
      console.log(this.$options.name + ' oder per Hand : App (conponent)', params)
      // this.stopSound.play()
      this.useAnimation = this.wheeldata.felder[this.gift].useAnimation
      this.useFrageDialog = this.wheeldata.felder[this.gift].useFrageDialog

      if (this.useAnimation) {
        this.showAnimation()
      }
      else if(this.useFrageDialog) {
        this.showFrage()
      }
      else {
        this.zeigeWheel()
      }  

    },

    showAnimation: function () {
      this.awardImg = this.wheeldata.felder[this.gift].animationlogo
      this.animationState = 'awardAnimation'
      this.hidden = "hidden";
      console.log("done -> hidden: " + this.hidden)
      // setTimeout(() => {this.zeigeWheel()}, 3000)
    },

/** Wird entweder am Ende der Animation oder von done() aufgerufen */
    showFrage: function () {
      if(this.useFrageDialog) {
        this.hidden = "hidden"
        this.showFrageDialog = true
      } else {
        this.zeigeWheel()
      }
    },

    /** @deprecated
     * wenn ich das richtig sehe .. 
     */
    reStartWheel: function () {
      this.unClick()
      this.spinTheWheel()
    },

    /** das LW ist wieder scharf und kann gestartet werden */
    unClick: function () {
      this.clicked = false
      console.log("unClick -> clicked: " + this.clicked)
    },

    /** könnte auch restart heissen :) 
     * 
     */
    zeigeWheel: function () {
        this.hidden = "";
        console.log("zeigeWheel called - > hidden: " + this.hidden)
        this.animationState = ""
        this.showFrageDialog = false
        this.unClick();
        this.$emit('emitDone')

        this.setdata(this.config)
    },

    spinTheWheel: function () {
        if (!this.clicked) {
          this.clicked = true
          console.log("spinTheWheel - classic -> clicked: " + this.clicked)
          console.log("spinTheWheel - classic -> gift: " + this.gift)
          this.$refs.wheel.spin()
        // this.startSound.play()
        }
    },

    /** jedes Feld ist gleichberechtig und hat die gleichen Warscheinlichkeiten  */
    randomGift: function () {
      const g = Math.floor(Math.random() * this.wheeldata.felder.length)
      console.log("randomGift -> " + g )
      return g
    },

    /** die Warscheinlichkeiten bzw die Häufigkeit für jedes Feld wird im Editor festgelegt und hier Ausgewertet */
    getCheatetGift: function () {
      console.log("- start getCheatetGift -")
      let prozent = 0
      let value = Math.random() * 100.0

      for (let i = 0; i < this.wheeldata.felder.length; i++) {
        prozent = prozent + Number(this.wheeldata.felder[i].relativeFrequency)
        if (value < prozent) {
          console.log("- getCheatetGift : " + i + " by value: ", value)
          return i
        }
      }

      console.error(" FAILURE - NO CHEATED GIFT FOUND ... sind alle Prozente richtig gesezt ??? !!! : " + value);
      return 0  // damit s keinen Error gibt ... 
    },

    easeOut: function () {
      let maxR = Math.PI + Math.PI / (this.gift+1)
      this.t = this.t + 0.005
      let r = this.easeOutElastic(this.t) ;
      console.log("easeOut", r)

      if (r != 1 ) {
        setTimeout(() => {
          this.easeOut();
        }, 20);
      }
      this.r = r * maxR
    },
    easeOutElastic: function (x) {
         return Math.sin((x * Math.PI) / 2);
    }

  },
}
</script>

<style scoped>

  .frageContainer {
    z-index: 2000;
  }

  .radContainer {
    background-color: transparent;
  }

  .rad {
    position: absolute;
    z-index: -10;
    left: 2vw;
    top: 23.5vh;
  }

  .portrait {
    width: 96vw;
    height: 96vw;
  }

  .landscape {
    width: 96vh;
    height: 96vh;
  }

  .drehscheibe {
    z-index: 20;
  }

  .wheel_wrap {
    display: block;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* cursor: none; */
    user-select: none;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    overflow: hidden;
  }

  .background {
    z-index: -10;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  .backgroundimg {
    max-width: 100vw;
    width: 100vw;
  }

  .hidden {
    display: none;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(0,0);
        opacity: 0;
    }
    20% {
        transform: scale(1.2,1.2);
        opacity: 1;
    }
    40% {
        transform: scale(1,1);
        opacity: 1;
    }
   70% {
        transform: scale(1.2,1.2);
        opacity: 1;
    }
    100% {
        transform: scale(0,0);
        opacity: 0;
    }
  }

  .awardContainer {
    z-index: 1000;
    position: absolute;
  }
  .awardContainer img {
    opacity: 0;
  }

  .awardAnimation img{
    animation: zoominoutsinglefeatured 3s ease-in-out;
  }

  .nSBilder {
    position: absolute;
    z-index: 1000;
    overflow: hidden;
  }

  .nsImg {
    width: 100%;
  }
</style>
